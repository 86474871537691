import styled from 'styled-components';

const GdpaBannerWrapper = styled.section`
  .react-cookie-law-accept-btn {
    background: ${props => props.theme.primaryColor};
    color: ${props => props.theme.white};
    padding: 14px;
    border-radius: 3px;
    cursor: pointer;
    border: 0;
    min-width: 170px;
    font-size: 16px;
    transition: 450ms all;
    &:hover {
      background: ${props => props.theme.secondaryColor};
    }
  }

  .react-cookie-law-dialog {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 100000;
    background-color: #fffefa;
    padding: 10px;
  }

  .react-cookie-law-option-wrapper {
    span {
      height: auto;
      width: auto;
      min-height: 14px;
      font-size: 12pt;
      color: ${props => props.theme.secondaryColor};
      display: inline-block;
      padding: 1px 0px 0px 23px;
      position: relative;
      top: 0px;
      left: 0px;
      z-index: 1;
      cursor: default;
      vertical-align: top;
    }
  }

  .react-cookie-law-option-checkbox {
    position: absolute;
    top: 4px;
    left: 0px;
    width: 14px;
    height: 14px;
    z-index: 2;
    cursor: pointer;
  }
`;

export default GdpaBannerWrapper;
