import React from "react";
import { Trans } from 'common/src/reusecore/Trans';
import { track } from 'common/src/reusecore/Tracking';

import GdprBannerWrapper from './gdpr.style';
import { CookieBanner } from '@palmabit/react-cookie-law';

const GdprBanner = () => {
  const selectedOptions = {
    preferences: true,
  };
  return (
    <GdprBannerWrapper>
      <CookieBanner
        message={<Trans i18nKey="gdpr.message" />}
        necessaryOptionText={<Trans i18nKey="gdpr.necessary" />}
        preferencesOptionText={<Trans i18nKey="gdpr.preferences" />}
        privacyPolicyLinkText={<Trans i18nKey="gdpr.privacyPolicy" />}
        acceptButtonText={<Trans i18nKey="gdpr.accept" />}
        showPreferencesOption={true}
        showStatisticsOption={false}
        showMarketingOption={false}
        onAccept={() => track('acceptGdpr', selectedOptions)}
        onAcceptPreferences={() => selectedOptions.preferences = true}
        onDeclinePreferences={() => selectedOptions.preferences = false}
        policyLink="/privacy"
        styles={{
          button: {},
          dialog: {},
          optionLabel: {},
          checkbox: {},
        }}
      />
    </GdprBannerWrapper>
  );
};

export default GdprBanner;
